/* General Styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    color: #ffffff;
    background-color: #1E232C;
  }
  
  h1, h2, h3 {
    color: #EDBD61;
  }
  
  p {
    color: #ffffff;
    line-height: 1.6;
  }
  
  /* Hero Section */
  .parallax {
    background: url('https://1e3mmzzlwzxdh0d1.public.blob.vercel-storage.com/defaulEventBanner-6kKn362W2HBm8nyB4qGktatl0tBFIQ.png') no-repeat center center fixed; /* Replace with a suitable background image */
    background-size: cover;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overlay {
    display: flex;
    flex-direction: column;
    justify-self: center;
    background-color: rgba(35, 30, 69, 0.7);
    padding: 40px;
    text-align: center;
    border-radius: 10px;
  }
  
  .logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .main-title {
    font-size: 2.5rem;
  }
  
  .main-subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .cta-buttons {
    display: flex;
    justify-content: center;
  }
  
  .cta-button {
    background: #FFFFFF;
    color: #231E45;
    text-decoration: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    transition: 0.3s ease;
    margin-right: 10px;
    max-width: 150px;
  }
  
  .cta-button:hover {
    background: #bebebe;
    color: #231E45;
  }

  .cta-button2 {
    background: #EDBD61;
    color: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    transition: 0.3s ease;
    margin-right: 10px;
  }
  
  .cta-button2:hover {
    background: #c59f59;
    color: #ffffff;
  }
  
  /* Features Section */
  .features {
    padding: 40px 20px;
    background: #231E45;
    border-radius: 20px; 
    display: flex;
    flex-direction: column;
  }
  
  .container {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  .feature-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .feature-item {
    background: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .feature-item p {
    color: #1E232C;
  }
  
  /* How It Works Section */
  .how-it-works {
    padding: 40px 20px;
    text-align: center;
    background: #FFFFFF;
  }
  
  .how-it-works p {
    color: #1E232C;
  }
  
  /* Call-to-Action Section */
  .cta {
    background: #FFE6C2;
    color: #231E45;
    text-align: center;
    padding: 40px 20px;
  }
  
  .cta-title {
    color: #231E45;
  }
  
  /* Footer */
  .footer {
    text-align: center;
    padding: 10px;
    background: #231E45;
    color: #FFFFFF;
  }
  
.privacy-policy {
    background: #1E232C;
    color: #FFFFFF;
  }
  
  .container-privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 50px 20px;
  }
  
  .privacy-title {
    text-align: center;
    color: #EDBD61;
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  h2 {
    color: #EDBD61;
    margin-top: 20px;
  }
  
  p {
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  a {
    color: #EDBD61;
    text-decoration: underline;
  }
  
  a:hover {
    color: #FFFFFF;
  }
  
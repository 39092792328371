/* Header Styles */
.header {
    background-color: #231E45;
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex-direction: row;
  }
  
  .containerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo {
    width: 100px;
    margin-right: 10px;
    margin-bottom: 0;
  }
  
  .site-title {
    color: #EDBD61;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }
  
  .nav {
    display: flex;
  }
  
  .nav-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-link {
    color: #FFFFFF;
    text-decoration: none;
    margin: 0 15px;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover {
    color: #EDBD61;
  }
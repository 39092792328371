.contact {
    background: #1E232C;
    color: #FFFFFF;
  }
  
  .container-contact {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 20px;
  }
  
  .contact-title {
    text-align: center;
    color: #EDBD61;
    margin-bottom: 10px;
    font-size: 2rem;
  }
  
  .contact-subtitle {
    text-align: center;
    margin-bottom: 20px;
    color: #FFFFFF;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #FFFFFF;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #231E45;
    border-radius: 5px;
    background: #FFFFFF;
    color: #1E232C;
  }
  
  textarea {
    resize: none;
    height: 100px;
  }
  
  .contact-submit {
    background: #EDBD61;
    color: #231E45;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .contact-submit:hover {
    background: #FFFFFF;
    color: #1E232C;
  }
  